import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, ref, toRaw, onMounted, computed } from 'vue';
import { selectKnTimeSettings, updateKnTimeSettings } from '@/api/attendance';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import multipleDatePicker from './components/multipleDatePicker';
import moment from 'moment';
import getFieldRules from '@/utils/fieldRules';
export default {
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    multipleDatePicker
  },

  setup() {
    const formRef = ref();
    const formState = reactive({
      weekdays: [],
      addMustDate: [],
      addNeednotDate: [],
      workTimeFirst: null,
      workTimeSecond: null,
      workTimeThird: null,
      timesNum: null,
      //上下班次数
      overInterval: null,
      //下班后几分钟后开始记为加班
      minDuration: null,
      //加班最小工时
      isOvertimeNeedApproval: null,
      //加班是否需要审批
      durationType: null,
      //加班工时计为类型
      overtimePay: null,
      //加班费
      workingTimeFirst: null,
      //第一次上班时间
      lateOutLateFirst: null,
      //晚到超时分钟数迟到
      halfOutLateFirst: null,
      //晚到超时分钟数半天
      clockEarlyLateFirst: null,
      //最早可提前分钟数打卡
      closingTimeFirst: null,
      //第一次下班时间
      earlyOutEarlyFirst: null,
      //早走超时分钟数早退
      halfOutEarlyFirst: null,
      //早走超时分钟数半天
      clockLateLateFirst: null,
      //最晚可延后分钟数打卡
      restStartTime: null,
      //中途休息时间开始时间
      restEndTime: null,
      //中途休息时间结束时间
      workingTimeSecond: null,
      //第二次上班时间
      lateOutLateSecond: null,
      //早走超时分钟数早退
      halfOutLateSecond: null,
      //早走超时分钟数半天
      clockEarlyLateSecond: null,
      //最晚可延后分钟数打卡
      closingTimeSecond: null,
      //第二次下班时间
      earlyOutEarlySecond: null,
      //早走超时分钟数早退
      halfOutEarlySecond: null,
      //早走超时分钟数半天
      clockLateLateSecond: null,
      //最晚可延后分钟数打卡
      workingTimeThird: null,
      //第三次上班时间
      lateOutLateThird: null,
      //早走超时分钟数早退
      halfOutLateThird: null,
      //早走超时分钟数半天
      clockEarlyLateThird: null,
      //最晚可延后分钟数打卡
      closingTimeThird: null,
      //第三次下班时间
      earlyOutEarlyThird: null,
      //早走超时分钟数早退
      halfOutEarlyThird: null,
      //早走超时分钟数半天
      clockLateLateThird: null,
      //最晚可延后分钟数打卡
      knLateRules: [{
        ruleId: null,
        times: null,
        deductionAmount: null
      }],
      absenceType: null,
      customType: null,
      customMoney: null,
      isRestDayOvertime: null,
      isRequiredRest: false
    });
    const {
      validatePass
    } = getFieldRules();
    const rules = {
      workShift: [{
        required: true,
        message: '上班时间不能为空',
        trigger: 'change' // trigger: 'change',

      }],
      desc: [{
        required: true,
        message: '反馈内容不能为空',
        trigger: 'blur'
      }] // mobile: [
      //   {
      //     required: true,
      //     validator: checkPhone,
      //     trigger: 'blur',
      //   },
      // ],
      // email: [
      //   {
      //     required: true,
      //     validator: email,
      //     trigger: 'blur',
      //   },
      // ],

    };
    const loading = ref(false);

    const onSubmit = () => {
      // console.log('values', formState);
      formRef.value.validate().then(async () => {
        if (new Date(`2022-02-24 ${formState.closingTimeFirst}`) <= new Date(`2022-02-24 ${formState.workingTimeFirst}`)) {
          _message.error('第一次上班时间不能大于或等于第一次下班时间');

          return;
        }

        if (formState.timesNum === 1 && formState.isRequiredRest) {
          if (new Date(`2022-02-24 ${formState.restStartTime}`) <= new Date(`2022-02-24 ${formState.workingTimeFirst}`)) {
            _message.error('第一次中途休息时间开始时间不能小于或等于第一次上班时间');

            return;
          }

          if (new Date(`2022-02-24 ${formState.restEndTime}`) >= new Date(`2022-02-24 ${formState.closingTimeFirst}`)) {
            _message.error('第一次中途休息时间结束时间不能大于或等于第一次下班时间');

            return;
          }
        }

        if (new Date(`2022-02-24 ${formState.closingTimeFirst}`) >= new Date(`2022-02-24 ${formState.workingTimeSecond}`) && formState.timesNum !== 1) {
          _message.error('第二次上班时间不能大于或等于第一次下班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.workingTimeSecond}`) >= new Date(`2022-02-24 ${formState.closingTimeSecond}`) && formState.timesNum !== 1) {
          _message.error('第二次上班时间不能大于或等于第二次下班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.closingTimeSecond}`) >= new Date(`2022-02-24 ${formState.workingTimeThird}`) && formState.timesNum === 3) {
          _message.error('第二次下班时间不能大于或等于第三次上班时间');

          return;
        }

        if (new Date(`2022-02-24 ${formState.workingTimeThird}`) >= new Date(`2022-02-24 ${formState.closingTimeThird}`) && formState.timesNum === 3) {
          _message.error('第三次上班时间不能大于或等于第三次下班时间');

          return;
        }

        let isOk = false;
        isOk = findDataIsOk(formState.knLateRules, formState.knLateRules.length - 1);

        if (isOk) {
          _message.error('迟到/早退次数必须顺序增加');

          return;
        }

        if (!formState.timesNum) {
          _message.error('请选择上下班次数');

          return;
        }

        if (!formState.weekdays) {
          _message.error('请选择工作日');

          return;
        }

        let isOkForDate = false;
        formState.addMustDate.forEach(item => {
          formState.addNeednotDate.forEach(ixt => {
            if (item === ixt) {
              isOkForDate = true;
              return;
            }
          });
        });

        if (isOkForDate) {
          _message.error('新增必须打卡日期不能和新增无需打卡日期有相同日期');

          return;
        }

        if (formState.absenceType == 4 && formState.customMoney == null) {
          _message.error('缺卡扣款自定义扣款金额不能为空！');

          return;
        }

        loading.value = true; // console.log(11111111)

        let obj = {};
        Object.assign(obj, formState);
        obj.weekdays = obj.weekdays.join(',');
        obj.addMustDate = obj.addMustDate.join(',');
        obj.addMustDate = obj.addMustDate === "" ? null : obj.addMustDate;
        obj.addNeednotDate = obj.addNeednotDate.join(',');
        obj.addNeednotDate = obj.addNeednotDate === "" ? null : obj.addNeednotDate;
        obj.isRestDayOvertime = obj.isRestDayOvertime ? 1 : 0; // console.log(obj)

        let res = await updateKnTimeSettings(obj);

        if (res.code === 200) {
          _message.success(res.message);
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //迟到/早退次数比较


    const findDataIsOk = (arr, i) => {
      if (i == 0) {
        return false;
      } else {
        if (arr[i].times > arr[i - 1].times) {
          return findDataIsOk(arr, i - 1);
        } else {
          return true;
        }
      }
    }; //时间转成分钟


    const time_to_sec = time => {
      // console.log(time)
      if (time !== null) {
        var s = "";
        var hour = time.split(":")[0];
        var min = time.split(":")[1];
        s = Number(hour * 60) + Number(min);
        return s;
      } else {
        return 0;
      }
    };

    const selectKnTimeSetting = async () => {
      const param = {
        timesNum: formState.timesNum
      };
      let res = await selectKnTimeSettings(param);

      if (res.code === 200) {
        Object.assign(formState, res.data);
        formState.weekdays = formState.weekdays === null ? null : formState.weekdays.split(',');
        formState.addMustDate = formState.addMustDate === null ? [] : formState.addMustDate.split(',');
        formState.addNeednotDate = formState.addNeednotDate === null ? [] : formState.addNeednotDate.split(',');

        if (formState.restStartTime && formState.restEndTime) {
          formState.isRequiredRest = true;
        }

        formState.isRestDayOvertime = res.data.isRestDayOvertime ? true : false; // console.log('wwwwwwww',formState.addMustDate)
      } else {
        _message.error(res.message);
      }
    };

    const numberEcho = (val, name, num, a, b) => {
      if (b === undefined) {
        if (!val) {
          formState[name] = num;
        }
      } else {
        if (!val) {
          formState.knLateRules[name][a] = num;
        }
      }
    }; //添加


    const addMaterialInformation = () => {
      formState.knLateRules.push({
        times: 0,
        deductionAmount: 0
      });
    }; //删除


    const deleteMaterialInformation = index => {
      formState.knLateRules.splice(index, 1);
    };

    const changeRadioNumber = () => {
      selectKnTimeSetting();
    };

    const workTimeFirst = computed(() => {
      let time = time_to_sec(formState.closingTimeFirst) - time_to_sec(formState.workingTimeFirst) - (time_to_sec(formState.restEndTime) - time_to_sec(formState.restStartTime)); //  console.log(1111,time)

      return (Math.floor(time / 60).toString().length < 2 ? "0" + Math.floor(time / 60).toString() : Math.floor(time / 60).toString()) + "小时" + ((time % 60).toString().length < 2 ? "0" + (time % 60).toString() : (time % 60).toString()) + "分钟";
    });
    const workTimeSecond = computed(() => {
      let time = time_to_sec(formState.closingTimeFirst) - time_to_sec(formState.workingTimeFirst) + (time_to_sec(formState.closingTimeSecond) - time_to_sec(formState.workingTimeSecond)); //  console.log(2222,time)

      return (Math.floor(time / 60).toString().length < 2 ? "0" + Math.floor(time / 60).toString() : Math.floor(time / 60).toString()) + "小时" + ((time % 60).toString().length < 2 ? "0" + (time % 60).toString() : (time % 60).toString()) + "分钟";
    });
    const workTimeThird = computed(() => {
      let time = time_to_sec(formState.closingTimeFirst) - time_to_sec(formState.workingTimeFirst) + (time_to_sec(formState.closingTimeSecond) - time_to_sec(formState.workingTimeSecond)) + (time_to_sec(formState.closingTimeThird) - time_to_sec(formState.workingTimeThird));
      return (Math.floor(time / 60).toString().length < 2 ? "0" + Math.floor(time / 60).toString() : Math.floor(time / 60).toString()) + "小时" + ((time % 60).toString().length < 2 ? "0" + (time % 60).toString() : (time % 60).toString()) + "分钟";
    });

    const selectValue = val => {
      formState.addMustDate = val;
    };

    const selectValue1 = val => {
      formState.addNeednotDate = val;
    };

    const changeCheckbox = e => {
      console.log(1111, e);

      if (!e.target.checked) {
        formState.restStartTime = null;
        formState.restEndTime = null;
      }
    };

    onMounted(() => {
      selectKnTimeSetting();
    });
    return {
      formRef,
      formState,
      rules,
      onSubmit,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      numberEcho,
      addMaterialInformation,
      deleteMaterialInformation,
      changeRadioNumber,
      loading,
      workTimeFirst,
      workTimeThird,
      workTimeSecond,
      selectValue,
      selectValue1,
      validatePass,
      changeCheckbox
    };
  }

};