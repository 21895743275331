import { createVNode as _createVNode, isVNode as _isVNode, resolveComponent as _resolveComponent } from "vue";
import moment from 'moment';

function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}

export default {
  props: {
    selectClassName: {
      type: String,
      default: "multipleDropdownClassName"
    },
    dateValues: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
      // selectValue: [],
      open: false
    };
  },

  computed: {
    dateValue: {
      get() {
        return this.dateValues ? this.dateValues : [];
      },

      set(val) {
        let arr = [];
        val.forEach(item => {
          arr.push(moment(item).format("YYYY-MM-DD"));
        });
        this.$emit('selectValue', arr);
      }

    }
  },
  methods: {
    handleFocus() {
      this.open = true;
    },

    handleSelectVisibleChange(v) {
      this.open = v;
    },

    handleOpenChange(v) {
      if (!v) {
        this.open = false;
      }
    },

    getCalendarContainer() {
      return document.querySelector(`.${this.selectClassName}`);
    },

    handlePickerChange(date) {
      const t = this.getTimestamp(date);
      const index = this.dateValue.indexOf(t);
      const clone = JSON.parse(JSON.stringify(this.dateValue));

      if (index > -1) {
        clone.splice(index, 1);
      } else {
        clone.push(t);
      } // emit


      this.dateValue = clone;
    },

    getTimestamp(value) {
      return value.startOf("day").format('YYYY-MM-DD');
    },

    renderTag({
      value,
      onClose
    }) {
      let _slot;

      const handleClose = () => {
        onClose(); // onChange && onChange(selectedDate.filter((t) => t !== value));
        // emit

        this.dateValue = this.dateValue.filter(t => t !== value);
      };

      return _createVNode(_resolveComponent("a-tag"), {
        "onClose": handleClose,
        "closable": true
      }, _isSlot(_slot = moment(value).format("YYYY-MM-DD")) ? _slot : {
        default: () => [_slot]
      });
    },

    dateRender({
      current: currentDate
    }) {
      // const isSelected = this.selectValue.indexOf(this.getTimestamp(currentDate)) > -1;
      const isSelected = this.dateValue.indexOf(this.getTimestamp(currentDate)) > -1;
      return _createVNode("div", {
        "className": "ant-picker-cell-inner",
        "style": isSelected ? {
          position: "relative",
          zIndex: 2,
          display: "inlineBlock",
          width: "24px",
          height: "22px",
          lineHeight: "22px",
          backgroundColor: "#1890ff",
          color: "#fff",
          margin: "auto",
          borderRadius: "2px",
          transition: "background 0.3s, border 0.3s"
        } : {}
      }, [currentDate.date()]);
    },

    handleChange(value) {
      // console.log(value)
      this.dateValue = value;
    }

  }
};