import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_date_picker = _resolveComponent("a-date-picker");

  const _component_a_select = _resolveComponent("a-select");

  return _openBlock(), _createBlock(_component_a_select, {
    style: {
      "width": "80%"
    },
    dropdownClassName: $props.selectClassName,
    mode: "tags",
    allowClear: "",
    placeholder: "请选择日期",
    value: $options.dateValue,
    open: $data.open,
    dropdownStyle: {
      height: '300px',
      width: '280px',
      minWidth: '0'
    },
    tagRender: $options.renderTag,
    onFocus: $options.handleFocus,
    onChange: $options.handleChange
  }, {
    dropdownRender: _withCtx(() => [_createVNode(_component_a_date_picker, {
      style: {
        "visibility": "hidden"
      },
      value: "",
      valueFormat: "",
      getCalendarContainer: $options.getCalendarContainer,
      open: true,
      showToday: false,
      dateRender: $options.dateRender,
      onOpenChange: $options.handleOpenChange,
      onChange: $options.handlePickerChange
    }, null, 8, ["getCalendarContainer", "dateRender", "onOpenChange", "onChange"])]),
    _: 1
  }, 8, ["dropdownClassName", "value", "open", "tagRender", "onFocus", "onChange"]);
}